import { Box } from '@mui/material';
import FormFields from '../../../../form/FormFields';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideocamIcon from '@mui/icons-material/Videocam';
import StartIcon from '@mui/icons-material/Start';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const CalMeetingForm = ({
  dataCollection,
  onChange,
  calendarOptions,
  onValidationChange,
}) => {
  const { t } = useTranslation();

  const fieldsWholeDay = [
    {
      label: t('Name'),
      key: 'label',
      type: 'text',
      colspan: 2,
    },
    {
      label: t('Start Date'),
      key: 'startDate',
      type: 'date',
      colspan: 1,
      Icon: StartIcon,
    },
    {
      label: t('End Date'),
      key: 'endDate',
      type: 'date',
      colspan: 1,
      Icon: KeyboardTabIcon,
    },
    {
      label: t('Project'),
      key: 'project',
      type: 'project',
      colspan: 2,
    },
    {
      label: t('Online Meeting'),
      key: 'onlineMeeting',
      type: 'meeting',
      Icon: VideocamIcon,
      colspan: 2,
    },
    {
      label: t('Location'),
      key: 'location',
      type: 'address',
      Icon: LocationOnIcon,
      colspan: 2,
    },
    {
      label: t('Whole Day'),
      key: 'wholeDay',
      type: 'checkbox',
      colspan: 2,
      Icon: DateRangeIcon,
    },
    {
      label: t('Meeting is already accepted'),
      key: 'meetingIsConfirmed',
      type: 'checkbox',
      colspan: 2,
      Icon: DoneAllIcon,
    },
    {
      label: t('Owner'),
      key: 'owner',
      type: 'user',
      colspan: 2,
    },
    {
      label: t('Participants'),
      key: 'participants',
      type: 'participants',
      colspan: 2,
    },
    {
      label: t('Agenda'),
      key: 'agenda',
      type: 'richText',
      colspan: 2,
      Icon: FormatListNumberedIcon,
    },
    {
      label: t('Internal note'),
      key: 'internalNote',
      type: 'richText',
      colspan: 2,
      Icon: EditNoteIcon,
    },
  ];
  const validationSchemaWholeDay = Yup.object().shape({
    startDate: Yup.string().required(t('Is required')),
    endDate: Yup.string().required(t('Is required')),
    label: Yup.string().required(t('Is required')),
    onlineMeeting: Yup.object().shape({
      type: Yup.string().nullable(),
      url: Yup.string().url().nullable(),
    }),
    location: Yup.object()
      .shape({
        street: Yup.string(),
        houseNumber: Yup.string(),
        zipCode: Yup.string(),
        city: Yup.string(),
        countryCode: Yup.string(),
      })
      .nullable(),
  });

  const fieldsHalfDay = [
    {
      label: t('Name'),
      key: 'label',
      type: 'text',
      colspan: 2,
    },
    {
      label: t('Start Date'),
      key: 'startDate',
      type: 'date',
      colspan: 1,
      Icon: StartIcon,
    },
    {
      label: t('Start Time'),
      key: 'startTime',
      type: 'time',
      colspan: 1,
    },
    {
      label: t('End Date'),
      key: 'endDate',
      type: 'date',
      colspan: 1,
      Icon: KeyboardTabIcon,
    },
    {
      label: t('End Time'),
      key: 'endTime',
      type: 'time',
      colspan: 1,
    },
    {
      label: t('Project'),
      key: 'project',
      type: 'project',
      colspan: 2,
    },
    {
      label: t('Online Meeting'),
      key: 'onlineMeeting',
      type: 'meeting',
      Icon: VideocamIcon,
      colspan: 2,
    },
    {
      label: t('Location'),
      key: 'location',
      type: 'address',
      Icon: LocationOnIcon,
      colspan: 2,
    },
    {
      label: t('Whole Day'),
      key: 'wholeDay',
      type: 'checkbox',
      colspan: 2,
      Icon: DateRangeIcon,
    },
    {
      label: t('Meeting is already accepted'),
      key: 'meetingIsConfirmed',
      type: 'checkbox',
      colspan: 2,
      Icon: DoneAllIcon,
    },
    {
      label: t('Owner'),
      key: 'owner',
      type: 'user',
      colspan: 2,
    },
    {
      label: t('Participants'),
      key: 'participants',
      type: 'participants',
      colspan: 2,
    },
    {
      label: t('Agenda'),
      key: 'agenda',
      type: 'richText',
      colspan: 2,
      Icon: FormatListNumberedIcon,
    },
    {
      label: t('Internal note'),
      key: 'internalNote',
      type: 'richText',
      colspan: 2,
      Icon: EditNoteIcon,
    },
  ];
  const validationSchemaHalfDay = Yup.object().shape({
    startDate: Yup.string().required(t('Is required')),
    startTime: Yup.string().required(t('Is required')),
    endDate: Yup.string().required(t('Is required')),
    endTime: Yup.string().required(t('Is required')),
    label: Yup.string().required(t('Is required')),
    onlineMeeting: Yup.object().shape({
      type: Yup.string().nullable(),
      url: Yup.string().url().nullable(),
    }),
    location: Yup.object()
      .shape({
        street: Yup.string(),
        houseNumber: Yup.string(),
        zipCode: Yup.string(),
        city: Yup.string(),
        countryCode: Yup.string(),
      })
      .nullable(),
  });

  return (
    <Box className="grid grid-cols-2 gap-3 w-full">
      <FormFields
        fields={dataCollection.wholeDay == '1' ? fieldsWholeDay : fieldsHalfDay}
        dataCollection={dataCollection}
        updateDataCollection={onChange}
        validationSchema={
          dataCollection.wholeDay == '1'
            ? validationSchemaWholeDay
            : validationSchemaHalfDay
        }
        size={'small'}
        onValidationChange={onValidationChange}
      />
    </Box>
  );
};
