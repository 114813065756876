import React, { useState } from 'react';
import { updateOrCreate } from '../../../special/updateOrCreate';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { NewCalendar } from './NewCalendar';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ExoVariants } from '../../ExoVariants';
import GoogleIcon from '@mui/icons-material/Google';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { NewGoogleCalendar } from './NewGoogleCalendar';
import ExoDialog from '../../ExoDialog';
import { ProjectAddElementButton } from '../../project/ProjectAddElementButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { SettingsPopover } from '../../project/SettingsPopover';
import { tokens } from '../../../../global/theme/tokens';
import { ShareCalendar } from './ShareCalendar';

export const ExoCalList = ({ calendars, onChange, onDelete }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);
  const [share, setShare] = useState(null);

  function handleClick(cal) {
    const newCal = { ...cal, isInactive: !cal.isInactive };
    handleUpdate(newCal);
  }

  function handleUpdate(cal) {
    setOpen(false);
    setEdit(null);
    onChange(updateOrCreate(calendars, cal));
  }

  const variants = [
    {
      label: t('Exo Calendar'),
      Icon: AddCircleOutlineIcon,
      content: (
        <NewCalendar onClose={() => setOpen(false)} onCreate={handleUpdate} />
      ),
    },
    {
      label: t('Google Calendar'),
      Icon: GoogleIcon,
      content: <NewGoogleCalendar onClose={() => setOpen(false)} />,
    },
  ];

  return (
    <Box
      className="flex flex-col gap-2 p-2"
      sx={{
        width: '500px',
        maxWidth: '100%',
      }}
    >
      <Typography variant="h3" fontWeight={500}>
        <CalendarMonthIcon className="mr-2" />
        {t('Calendars')}
      </Typography>
      {calendars?.map((cal) => (
        <Calendar
          key={cal.id}
          cal={cal}
          onSelect={handleClick}
          onEdit={setEdit}
          onDelete={() => onDelete(cal, calendars)}
          onShare={setShare}
          showSettings
        />
      ))}
      <ProjectAddElementButton
        label={t('Add Calendar')}
        size="small"
        onClick={() => setOpen(true)}
      />
      <ExoDialog open={open} onClose={() => setOpen(false)} limitWidth>
        <ExoVariants variants={variants} />
      </ExoDialog>
      <ExoDialog open={edit} onClose={() => setEdit(null)} limitWidth>
        <NewCalendar
          onClose={() => setEdit(null)}
          startData={edit}
          onCreate={handleUpdate}
        />
      </ExoDialog>
      <ExoDialog open={share} onClose={() => setShare(null)} limitWidth>
        <ShareCalendar
          calendar={share}
          onClose={() => {
            setShare(null);
          }}
        />
      </ExoDialog>
    </Box>
  );
};

export const Calendar = ({
  cal,
  onSelect,
  noIcon,
  onEdit,
  onDelete,
  onShare,
  showSettings,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { color, name, id, isInactive } = cal;

  const Container = onSelect ? Button : Box;

  const settings = [
    {
      label: t('Edit'),
      color: 'info',
      onClick: () => onEdit(cal),
    },
    {
      label: t('Share'),
      color: 'info',
      onClick: () => onShare(cal),
    },
    {
      label: t('Delete'),
      color: 'error',
      onClick: () => onDelete(cal),
    },
  ];

  return (
    <Box
      className="flex gap-2 w-full rounded-md"
      sx={{
        border: `1px solid transparent`,
        ':hover': {
          bgcolor: onSelect && colors.info + '20',
          border: onSelect && `1px solid ${colors.info}80`,
        },
      }}
    >
      <Container
        onClick={onSelect ? () => onSelect(cal) : null}
        sx={{
          opacity: isInactive ? 0.3 : 1,
        }}
        className=" whitespace-nowrap w-full"
        size="small"
      >
        <Typography
          textAlign="left"
          className="w-full h-full flex items-center"
        >
          {!noIcon && (
            <>
              {!isInactive ? (
                <VisibilityIcon sx={{ color, fontSize: 15 }} />
              ) : (
                <VisibilityOffIcon sx={{ color, fontSize: 15 }} />
              )}
            </>
          )}
          <span
            style={{
              backgroundColor: color,
              width: '5px',
              height: '18px',
              borderRadius: '2px',
              marginRight: '5px',
              marginLeft: noIcon ? '0px' : '10px',
            }}
          />

          {name}
        </Typography>
      </Container>
      {showSettings && cal.id && cal.writePermissions && (
        <SettingsPopover settings={settings} />
      )}
    </Box>
  );
};
