import {
  Box,
  Checkbox,
  useTheme,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import FormHelperText from "../FormHelperText";

const TypeCheckbox = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
  size,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleChange() {
    const currentValue =
      typeof dataCollection[field.key] === "boolean"
        ? dataCollection[field.key]
        : Boolean(parseInt(dataCollection[field.key]));

    updateValidationOnChange([field.key], !currentValue ? "1" : "0");
  }

  return (
    <Box
      onClick={handleChange}
      sx={{
        minHeight: size == "small" ? "40px" : "53px",
        borderRadius: "10px",
        bgcolor: colors.bgInput,
      }}
      className="flex flex-row items-center justify-start cursor-pointer pr-2"
    >
      <Checkbox
        color="secondary"
        checked={
          typeof dataCollection[field.key] === "boolean"
            ? dataCollection[field.key]
            : Boolean(parseInt(dataCollection[field.key]))
        }
      />
      <Typography>{field.label}</Typography>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </Box>
  );
};

export default TypeCheckbox;
