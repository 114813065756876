import { useTheme } from '@emotion/react';
import {
  Badge,
  Box,
  Button,
  Dialog,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { createElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../global/theme/tokens';
import { useConfig } from '../../../../api/api';
import InboxIcon from '@mui/icons-material/Inbox';
import ExoDialog from '../../ExoDialog';
import { ExoInviteList } from './ExoInviteList';
import useFetch from '../../../../api/useFetch';

export const ExoCalInbox = ({ calendars, onEventChange, onCalendarChange }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const config = useConfig();

  const { apiData } = useFetch('invites-counts');

  const [openInbox, setOpenInbox] = useState(false);
  const [badgeCount, setBadgeCount] = useState(
    apiData ? apiData.data : { new: 0, maybe: 0 }
  );
  const [inviteTab, setInviteTab] = useState('new');

  const statusMap = {
    0: 'new',
    3: 'maybe',
    1: 'processed',
    2: 'processed',
  };

  const tapPathMap = {
    new: 'invites?status[eq]=0',
    maybe: 'invites?status[eq]=3',
    processed: 'invites?status[ne]=0',
  };

  useEffect(() => {
    if (apiData) setBadgeCount(apiData.data);
  }, [apiData]);

  function handleEventChange(changedData) {
    const newBadgeCount = {
      ...badgeCount,
      [statusMap[changedData.data.status]]:
        badgeCount[statusMap[changedData.data.status]] + 1,
    };

    setBadgeCount(newBadgeCount);

    onEventChange(changedData.data.event);
  }

  return (
    <>
      <Button
        onClick={() => setOpenInbox(!openInbox)}
        sx={{
          margin: '0px',
          padding: '0px',
          bgcolor: colors.glass,
          ':hover': {
            bgcolor: colors.selected,
          },
          borderRadius: '8px',
          minWidth: '45px',
        }}
        className=" backdrop-blur-md"
      >
        <Badge badgeContent={badgeCount.new} color="success">
          <InboxIcon />
        </Badge>
      </Button>
      <ExoDialog
        open={openInbox}
        onClose={() => setOpenInbox(false)}
        limitWidth
      >
        <Box className="flex pb-1 items-center justify-between">
          <Typography variant="h3">{t('Invites')}</Typography>
          <ToggleButtonGroup
            size="small"
            value={inviteTab}
            exclusive
            onChange={(e) => setInviteTab(e.target.value)}
            aria-label="Project Status"
            className="backdrop-blur-lg"
          >
            <Badge badgeContent={badgeCount.new} color="success">
              <ToggleButton value="new">{t('New')}</ToggleButton>
            </Badge>
            <Badge badgeContent={badgeCount.maybe} color="success">
              <ToggleButton value="maybe">{t('Maybe')}</ToggleButton>
            </Badge>
            <ToggleButton value="processed">{t('Processed')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <ExoInviteList
          isActive={inviteTab === 'new'}
          activeTab="new"
          path={tapPathMap.new}
          calendars={calendars}
          onEventChange={handleEventChange}
          onCalendarChange={onCalendarChange}
          onBadgeChange={(newCount) =>
            setBadgeCount({ ...badgeCount, new: newCount })
          }
        />
        <ExoInviteList
          isActive={inviteTab === 'maybe'}
          activeTab="maybe"
          path={tapPathMap.maybe}
          calendars={calendars}
          onEventChange={handleEventChange}
          onCalendarChange={onCalendarChange}
          onBadgeChange={(newCount) =>
            setBadgeCount({ ...badgeCount, maybe: newCount })
          }
        />

        <ExoInviteList
          isActive={inviteTab === 'processed'}
          activeTab="processed"
          path={tapPathMap.processed}
          calendars={calendars}
          onEventChange={handleEventChange}
          onCalendarChange={onCalendarChange}
          onBadgeChange={(newCount) =>
            setBadgeCount({ ...badgeCount, processed: newCount })
          }
        />
      </ExoDialog>
    </>
  );
};
