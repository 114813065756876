import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ContactsIcon from '@mui/icons-material/Contacts';
import { useTranslation } from 'react-i18next';
import GroupsIcon from '@mui/icons-material/Groups';
import { createElement, useEffect, useState } from 'react';
import ExoDialog from '../../exo/ExoDialog';
import SelectCompanies from '../../select/SelectCompanies';
import SelectContacts from '../../select/SelectContacts';
import SelectEmployees from '../../select/SelectEmployees';
import ExoAvatar from '../../exo/ExoAvatar';
import { SettingsPopover } from '../../exo/project/SettingsPopover';
import { updateOrCreate } from '../../special/updateOrCreate';
import { tokens } from '../../../global/theme/tokens';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import HelpIcon from '@mui/icons-material/Help';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import EmailIcon from '@mui/icons-material/Email';
import uuid from 'react-uuid';

const TypeParticipants = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  size,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const [partCollection, setPartCollection] = useState({});

  const [open, setOpen] = useState(null);

  function handleChange(key, newList) {
    // select default mail
    var updatedList = [];
    newList.forEach((element) => {
      if (key === 'employees' || element.selectedEmail)
        return updatedList.push({ ...element });

      var selectedEmail = null;
      element.emails.forEach((email) => {
        if (email.isPrimary) return (selectedEmail = email.emailAddress);
      });

      updatedList.push({
        ...element,
        selectedEmail,
        hasNoMail: !selectedEmail,
      });
    });

    // create new participants list
    const newPartList = { ...partCollection, [key]: updatedList };
    setPartCollection(newPartList);

    var newParticipantsReq = [];
    // add users
    newPartList.employees?.forEach((element) => {
      newParticipantsReq.push({
        type: 'user',
        participant: element,
        email: element.email,
      });
    });

    // add contacts
    newPartList.contacts?.forEach((element) => {
      newParticipantsReq.push({
        type: 'contact',
        participant: element,
        email: element.selectedEmail,
      });
    });

    // add companies
    newPartList.companies?.forEach((element) => {
      newParticipantsReq.push({
        type: 'company',
        participant: element,
        email: element.selectedEmail,
      });
    });

    updateValidationOnChange(field.key, newParticipantsReq);
  }

  function handleMailSelect(key, value) {
    const newList = updateOrCreate(partCollection[key], value);
    handleChange(key, newList);
  }

  const typeToPartKey = {
    company: 'companies',
    contact: 'contacts',
    user: 'employees',
  };

  function convertToPartData(data) {
    var newPartData = {};

    data?.forEach((element) => {
      if (!newPartData[typeToPartKey[element.type]])
        newPartData[typeToPartKey[element.type]] = [];
      newPartData[typeToPartKey[element.type]].push({
        ...element.participant,
        status: element.status,
        selectedEmail: element.email,
        hasNoMail: !element.email,
      });
    });

    return newPartData;
  }

  useEffect(() => {
    setPartCollection(convertToPartData(dataCollection[field.key]));
  }, [dataCollection[field.key]]);

  const statusMap = {
    0: {
      color: colors.grey[400],
      Icon: RadioButtonUncheckedIcon,
      bgTrans: '40',
    },
    1: {
      color: colors.success,
      Icon: ThumbUpIcon,
      bgTrans: '20',
    },
    2: {
      color: colors.error,
      Icon: ThumbDownIcon,
      bgTrans: '20',
    },
    3: {
      color: colors.warning,
      Icon: HelpIcon,
      bgTrans: '20',
    },
  };

  function removeElement(type, elementToRemove) {
    const updatedList = (partCollection[type] || []).filter(
      (obj) => obj.id !== elementToRemove.id
    );

    return handleChange(type, updatedList);
  }

  return (
    <Box className="pb-2">
      <Box className="flex flex-col md:flex-row gap-2 items-start">
        <Box>
          <Typography className="pl-1 pb-1">{field.label}</Typography>

          <ToggleButtonGroup value={''} exclusive>
            <ToggleButton
              onClick={() => setOpen('companies')}
              value="company"
              sx={{
                width: size == 'small' ? '35px' : '53px',
                borderRadius: '10px 0px 0px 10px',
              }}
              size={size}
            >
              <Tooltip title={t('Company')}>
                <ApartmentIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              onClick={() => setOpen('contacts')}
              value="contact"
              sx={{ width: size == 'small' ? '35px' : '53px' }}
              size={size}
            >
              <Tooltip title={t('Contact')}>
                <ContactsIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              onClick={() => setOpen('employees')}
              value="user"
              sx={{
                width: size == 'small' ? '35px' : '53px',
                borderRadius: ' 0px 10px 10px 0px',
              }}
              size={size}
            >
              <Tooltip title={t('Employee')}>
                <GroupsIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box className="w-full flex flex-col gap-2">
          {partCollection.companies && (
            <Box className="flex flex-col gap-1">
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {t('Companies')}
              </Typography>
              {partCollection.companies.map((company) => (
                <Company
                  key={'company-' + company.id}
                  company={company}
                  onMailSelect={handleMailSelect}
                  status={statusMap[company.status]}
                  onRemove={removeElement}
                  partCollection={partCollection}
                />
              ))}
            </Box>
          )}
          {partCollection.contacts && (
            <Box className="flex flex-col gap-1">
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {t('Contacts')}
              </Typography>
              {partCollection.contacts.map((contact) => (
                <Contact
                  key={'contact-' + contact.id}
                  contact={contact}
                  onMailSelect={handleMailSelect}
                  status={statusMap[contact.status]}
                  onRemove={() => removeElement('contacts', contact)}
                  partCollection={partCollection}
                />
              ))}
            </Box>
          )}
          {partCollection.employees && (
            <Box className="flex flex-col gap-1">
              <Typography variant="h6" fontSize={12} fontWeight={500}>
                {t('Employees')}
              </Typography>
              {partCollection.employees.map((employee) => (
                <Employee
                  key={'employee-' + employee.id}
                  employee={employee}
                  status={statusMap[employee.status]}
                  onRemove={removeElement}
                  partCollection={partCollection}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <ExoDialog open={open === 'companies'} onClose={() => setOpen(null)}>
        <SelectCompanies
          onSelect={(list) => handleChange('companies', list)}
          preSelected={partCollection.companies}
        />
      </ExoDialog>
      <ExoDialog open={open === 'contacts'} onClose={() => setOpen(null)}>
        <SelectContacts
          onSelect={(list) => handleChange('contacts', list)}
          preSelected={partCollection.contacts}
        />
      </ExoDialog>
      <ExoDialog open={open === 'employees'} onClose={() => setOpen(null)}>
        <SelectEmployees
          onSelect={(list) => handleChange('employees', list)}
          preSelected={partCollection.employees}
        />
      </ExoDialog>
    </Box>
  );
};

const Employee = ({ employee, status, onRemove, partCollection }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const { picture, salutation, firstName, lastName, email, position } =
    employee;

  const name = `${firstName} ${lastName}`;

  return (
    <Box
      className="flex items-center gap-2 rounded-md pl-1 justify-between"
      sx={{
        border: `1px solid ${status ? status.color : 'transparent'}`,
        bgcolor: status ? `${status.color}${status.bgTrans}` : 'transparent',
      }}
    >
      <Box className="flex items-center gap-2">
        {Boolean(status) && (
          <Tooltip title={status.label}>
            {createElement(status.Icon, {
              sx: { color: status.color },
            })}
          </Tooltip>
        )}
        <ExoAvatar picture={picture?.temporaryUrl} name={name} size="30px" />
        <Box className="flex flex-col">
          <Typography
            fontWeight={500}
          >{`${salutation} ${firstName} ${lastName}`}</Typography>
          <Typography fontSize={12} className="opacity-50">
            {position || email}
          </Typography>
        </Box>
      </Box>
      <SettingsPopover
        label={t('Actions')}
        settings={[
          {
            onClick: () => onRemove('users', employee),
            label: t('Remove'),
            color: 'error',
            Icon: PersonRemoveAlt1Icon,
          },
        ]}
      />
    </Box>
  );
};

const Contact = ({
  contact,
  onMailSelect,
  status,
  onRemove,
  partCollection,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const {
    salutation,
    firstName,
    lastName,
    emails,
    color,
    selectedEmail,
    hasNoMail,
  } = contact;
  const { t } = useTranslation();

  const [mailSettings, setMailSettings] = useState(null);

  useEffect(() => {
    const newSettings = [];

    emails.forEach((element) => {
      newSettings.push({
        onClick: () =>
          onMailSelect('contacts', {
            ...contact,
            selectedEmail: element.emailAddress,
          }),
        label: `${element.emailType} <${element.emailAddress}>`,
        Icon: EmailIcon,
      });
    });

    // add remove button
    newSettings.push({
      onClick: onRemove,
      label: t('Remove'),
      color: 'error',
      Icon: PersonRemoveAlt1Icon,
    });

    setMailSettings(newSettings);
  }, [partCollection]);

  const name = `${firstName} ${lastName}`;

  return (
    <Box
      className="flex items-center gap-2 rounded-md pl-1"
      sx={{
        border: `1px solid ${status ? status.color : 'transparent'}`,
        bgcolor: status ? `${status.color}${status.bgTrans}` : 'transparent',
      }}
    >
      {Boolean(status) && (
        <Tooltip title={status.label}>
          {createElement(status.Icon, { sx: { color: status.color } })}
        </Tooltip>
      )}
      <ExoAvatar size="30px" name={name} color={color} />
      <Box className="flex flex-col w-full justify-between">
        <Typography
          fontWeight={500}
        >{`${salutation} ${firstName} ${lastName}`}</Typography>
        <Typography
          fontSize={12}
          className="opacity-60"
          color={hasNoMail ? 'error' : ''}
        >
          {hasNoMail ? t('No email available') : selectedEmail}
        </Typography>
      </Box>
      {mailSettings && (
        <SettingsPopover label={t('E-Mails')} settings={mailSettings} />
      )}
    </Box>
  );
};
const Company = ({
  company,
  onMailSelect,
  status,
  onRemove,
  partCollection,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const {
    logo,
    salutation,
    firstName,
    lastName,
    emails,
    color,
    selectedEmail,
    nameShort,
    hasNoMail,
  } = company;
  const { t } = useTranslation();

  const [mailSettings, setMailSettings] = useState(null);

  useEffect(() => {
    const newSettings = [];

    emails.forEach((element) => {
      newSettings.push({
        onClick: () =>
          onMailSelect('companies', {
            ...company,
            selectedEmail: element.emailAddress,
          }),
        label: `${element.emailType} <${element.emailAddress}>`,
      });
    });

    // add remove button
    newSettings.push({
      onClick: () => onRemove('companies', company),
      label: t('Remove'),
      color: 'error',
      Icon: PersonRemoveAlt1Icon,
    });

    setMailSettings(newSettings);
  }, [partCollection]);

  return (
    <Box
      className="flex items-center gap-2 rounded-md pl-1"
      sx={{
        border: `1px solid ${status ? status.color : 'transparent'}`,
        bgcolor: status ? `${status.color}${status.bgTrans}` : 'transparent',
      }}
    >
      {Boolean(status) && (
        <Tooltip title={status.label}>
          {createElement(status.Icon, { sx: { color: status.color } })}
        </Tooltip>
      )}
      <ExoAvatar
        type="company"
        size="30px"
        picture={logo?.thumbnailUrl}
        name={nameShort}
        color={color}
      />
      <Box className="flex flex-col w-full justify-between">
        <Typography fontWeight={500}>{nameShort}</Typography>
        <Typography
          fontSize={12}
          className="opacity-50"
          color={hasNoMail ? 'error' : ''}
        >
          {hasNoMail ? t('No email available') : selectedEmail}
        </Typography>
      </Box>
      {mailSettings && (
        <SettingsPopover label={t('E-Mails')} settings={mailSettings} />
      )}
    </Box>
  );
};
export default TypeParticipants;
