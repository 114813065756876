import {
  Popper,
  Button,
  Box,
  useTheme,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from '@mui/material';
import { tokens } from '../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import { createElement, useEffect, useState } from 'react';
import { CalSickForm } from './types/sick/CalSickForm';
import { CalMeetingForm } from './types/meeting/CalMeetingForm';
import {
  API_DELETE,
  API_PATCH,
  API_POST,
  DBRequest,
  useConfig,
} from '../../../api/api';
import findDifferences from '../findDifferencesInDataCollection';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarSelect from './types/CalendarSelect';
import ExoAlert from '../ExoAlert';
import ExoDialog from '../ExoDialog';
import Cookies from 'universal-cookie';
import { CalMeetingShow } from './types/meeting/CalMeetingShow';
import ExoDrawer from '../ExoDrawer';

export const ExoCalPopper = ({
  anchorEl,
  open,
  onClose,
  data,
  calendars,
  onFeedback,
  onUpdate,
  onDelete,
  editMode,
  setEditMode,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();
  const { t } = useTranslation();

  const cookies = new Cookies();
  const userId = cookies.get('userId');

  const [type, setType] = useState('meeting');
  const [dataCollection, setDataCollection] = useState({});
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [firstCal, setFirstCal] = useState(null);

  // calendar
  useEffect(() => {
    if (calendars) {
      var newFirstCal = null;
      calendars.forEach((cal) => {
        if (!newFirstCal && cal.id)
          newFirstCal = { name: cal.name, id: cal.id, color: cal.color };
      });
      setSelectedCalendar(newFirstCal);
      setFirstCal(newFirstCal);
    }
  }, [calendars]);

  function handleChange(newData) {
    setDataCollection({ ...dataCollection, ...newData });
  }

  const typeEditMap = {
    meeting: CalMeetingForm,
    sick: CalSickForm,
  };

  const typeShowMap = {
    meeting: CalMeetingShow,
  };

  useEffect(() => {
    // set default data
    if (data && data.startDateTime && data.endDateTime && open) {
      if (data.id) {
        handleLoadEvent(data.id);
      } else {
        setDataCollection(meetingDefaultData(data, dataCollection));
      }
    }
  }, [data]);

  function handleLoadEvent(eventId) {
    DBRequest({
      config,
      path: `calendar-events/${eventId}`,
      onResponse: handleResponse,
      onLoading: setIsLoading,
    });
  }

  function handleResponse(res) {
    const event = res.data;
    const { userCalendar, startDateTime, endDateTime } = event;

    setSelectedCalendar({ ...userCalendar });
    setDataCollection({
      ...event,
      startTime: startDateTime,
      startDate: startDateTime,
      endTime: endDateTime,
      endDate: endDateTime,
    });
  }

  // startData functions
  function meetingDefaultData(event, dataCollection) {
    const { startDateTime, endDateTime } = event;

    // Get the current time and round it up to the next 30-minute mark
    const now = moment();
    const roundedStart = now
      .clone()
      .add(30 - (now.minute() % 30), 'minutes')
      .startOf('minute');

    // Set the endDateTime to 30 minutes after the rounded start time
    const roundedEnd = roundedStart.clone().add(30, 'minutes');

    return {
      ...dataCollection,
      startTime: roundedStart.toISOString(),
      startDate: startDateTime,
      endTime: roundedEnd.toISOString(),
      endDate: endDateTime,
      wholeDay: '1',
      meetingIsConfirmed: '0',
      label: t('New Meeting'),
      type: 'meeting',
      calendarId: firstCal.id,
      id: event.id,
      owner: {
        id: userId,
      },
    };
  }

  function handleSubmit(sendEmails) {
    const startMoment = moment(dataCollection.startTime);
    const endMoment = moment(dataCollection.endTime);

    const startTime = startMoment.format('HH:mm');
    const startDate = moment(dataCollection.startDate).format('YYYY-MM-DD');
    const startMomentMerged = moment(
      `${startDate} ${startTime}`,
      'YYYY-MM-DD HH:mm'
    );

    const endTime = endMoment.format('HH:mm');
    const endDate = moment(dataCollection.endDate).format('YYYY-MM-DD');
    const endMomentMerged = moment(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm');

    const reqData = {
      ...dataCollection,
      startDateTime: startMomentMerged.toISOString(),
      endDateTime: endMomentMerged.toISOString(),
      sendEmails: Boolean(sendEmails),
      calendarId: selectedCalendar.id,
    };
    DBRequest({
      config,
      path: dataCollection.id
        ? `calendar-events/${dataCollection.id}`
        : 'calendar-events',
      method: dataCollection.id
        ? API_PATCH(findDifferences(data, reqData))
        : API_POST(reqData),
      onFeedback,
      onResponse: (res) => {
        setDataCollection({});
        setOpenAlert(false);
        onUpdate({
          ...res.data,
          calendar: selectedCalendar,
          color: selectedCalendar.color,
        });
        setEditMode(false);
      },
      onError: () => setOpenAlert(false),
    });
    onClose();
  }

  // escape => cancel
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') handleRequest(event);

      if (event.key === 'Delete') handleDelete();
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dataCollection]);

  function handleClose(e) {
    if (e) e.preventDefault();
    setEditMode(false);
    onClose();
  }
  function handleRequest(e) {
    requestSubmit();
  }

  function handleDelete() {
    DBRequest({
      config,
      path: `calendar-events/${dataCollection.id}`,
      method: API_DELETE,
      onFeedback,
      onResponse: () => onDelete(dataCollection.id),
    });
    onClose();
  }

  const [openAlert, setOpenAlert] = useState(false);
  function requestSubmit() {
    if (type === 'meeting' && dataCollection.participants?.length > 0)
      return setOpenAlert(true);
    handleSubmit();
  }

  // mobile view
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const Container = isSmallScreen
    ? editMode || !data?.id
      ? ExoDialog
      : ExoDrawer
    : Popper;

  return (
    <Container
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      placement="left-start"
      className="z-40 "
      limitWidth
    >
      <Box
        className="flex flex-col gap-1 pl-3 py-3 rounded-md "
        sx={
          isSmallScreen
            ? {
                height: '100%',
              }
            : {
                background: `linear-gradient(135deg,  ${colors.glassFrom}, ${colors.glassTo})`,
                border: `1px solid ${colors.selected}`,
                transform: 'translate(-5px, 0px)',
                width: editMode || !data?.id ? '450px' : '300px',
                height: editMode || !data?.id ? '650px' : '350px',
                maxHeight: '90vh',
                backdropFilter: `blur(5px)`,
              }
        }
      >
        {!editMode && data?.id ? (
          typeShowMap[type] &&
          createElement(typeShowMap[type], {
            dataCollection,
            onClose: handleClose,
            onEdit: () => setEditMode(true),
            isSmallScreen,
            isLoading,
            wholeDayData: data?.wholeDay,
            writePermissions: data?.writePermissions,
          })
        ) : (
          <>
            <Box className="flex flex-col gap-2 h-full pr-3 overflow-y-auto">
              {data?.id && data?.writePermissions && (
                <IconButton
                  onClick={handleDelete}
                  className="top-2 right-2 z-20 backdrop-blur-sm"
                  sx={{
                    position: 'absolute',
                  }}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <Typography variant="h5" className="pb-1" fontWeight={500}>
                {dataCollection.id && data?.writePermissions
                  ? t('Edit Event')
                  : t('New Event')}
              </Typography>
              <Box className="flex flex-col md:flex-row gap-2">
                <ToggleButtonGroup
                  size="small"
                  value={type}
                  exclusive
                  onChange={(e) => setType(e.target.value)}
                  aria-label="Project Status"
                  className="mb-2 "
                  sx={{
                    '.MuiToggleButton-root': {
                      padding: '2px 5px',
                    },
                    display: 'none',
                  }}
                >
                  <ToggleButton value="meeting">{t('Meeting')}</ToggleButton>
                  <ToggleButton color="warning" value="sick">
                    {t('Sick')}
                  </ToggleButton>
                  <ToggleButton color="success" value="vacation">
                    {t('Vacation')}
                  </ToggleButton>
                </ToggleButtonGroup>

                {type === 'meeting' && (
                  <CalendarSelect
                    calendars={calendars}
                    selectedCal={selectedCalendar}
                    onSelect={setSelectedCalendar}
                  />
                )}
              </Box>

              {typeEditMap[type] &&
                createElement(typeEditMap[type], {
                  dataCollection,
                  onChange: handleChange,
                  onValidationChange: setIsValid,
                })}
            </Box>
            <Box className="flex justify-between pt-2 pr-3">
              <Button onClick={handleClose} variant="outlined" color="error">
                {t('Cancel')}
              </Button>
              <Button
                onClick={handleRequest}
                variant="contained"
                color="success"
                disabled={!isValid}
              >
                {t('Submit')}
              </Button>
            </Box>
          </>
        )}
      </Box>
      <ExoAlert
        open={openAlert}
        alert={{
          type: 'info',
          message: t('Should an e-mail be sent to the participants?'),
          submitLabel: t('Yes'),
          cancleLabel: t('No'),
        }}
        onClose={() => handleSubmit(false)}
        onSubmit={() => handleSubmit(true)}
      />
    </Container>
  );
};
