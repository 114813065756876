import React, { useState } from 'react';
import ExoForm from '../../ExoForm';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { API_POST, DBRequest, useConfig } from '../../../../api/api';

export const ShareCalendar = ({ calendar, onClose = () => {} }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [isLoading, setIsLoading] = useState(false);

  const fields = [
    {
      label: t('Recipient'),
      key: 'users',
      type: 'usersCheckbox',
      checkbox: {
        label: t('Write Permissions'),
        key: 'writePermissions',
        default: false,
      },
    },
  ];
  const validationSchema = Yup.object().shape({
    users: Yup.array().required('Users are required'),
  });

  function handleSubmit(data) {
    DBRequest({
      config,
      path: `calendars/${calendar.id}/share`,
      method: API_POST(data),
      onResponse: () => onClose(),
      onLoading: setIsLoading,
    });
  }

  return (
    <ExoForm
      isLoading={isLoading}
      fields={fields}
      onSubmit={handleSubmit}
      onCancle={onClose}
      header={t('Share Calendar')}
      validationSchema={validationSchema}
    />
  );
};
