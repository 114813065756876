import { Box, Skeleton, useTheme } from '@mui/material';
import { updateOrCreate } from '../../../special/updateOrCreate';
import { tokens } from '../../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../api/useFetch';
import { InviteMeeting } from './types/InviteMeeting';
import { createElement, useEffect } from 'react';
import NoDataAnimation from '../../../animation/NoDataAnimation';
import { InviteCalendar } from './types/InviteCalendar';

export const ExoInviteList = ({
  path,
  calendars,
  onEventChange,
  onCalendarChange,
  onBadgeChange,
  isActive,
  activeTab,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { apiData, setApiData } = useFetch(path);

  const statusMap = {
    0: 'new',
    3: 'maybe',
    1: 'processed',
    2: 'processed',
  };

  const typeMap = {
    meeting: InviteMeeting,
    calendar: InviteCalendar,
  };

  function handleChange(newInvite) {
    let newApiData;

    if (statusMap[newInvite.data.status] !== activeTab) {
      newApiData = {
        data: apiData.data.filter((el) => el.id != newInvite.data.id),
      };
    } else {
      newApiData = updateOrCreate(apiData, newInvite.data);
    }

    setApiData(newApiData);

    if (newInvite.data.type === 'meeting') {
      onEventChange(newInvite);
    }
  }

  useEffect(() => {
    if (apiData) onBadgeChange(apiData.data.length);
  }, [apiData]);

  if (!isActive) return;
  return (
    <Box className="flex flex-col gap-2 pt-3">
      {!apiData &&
        Array(3)
          .fill(null)
          .map((_, index) => (
            <Skeleton
              key={index}
              className="rounded-md"
              sx={{
                padding: 0,
                margin: 0,
                height: '196px',
                transform: 'unset',
              }}
            />
          ))}
      {apiData && apiData.data.length === 0 && (
        <Box className="flex h-full items-center justify-center">
          <NoDataAnimation />
        </Box>
      )}
      {apiData?.data.map((invite) =>
        createElement(typeMap[invite.type], {
          key: invite.id,
          invite: invite,
          onChange: handleChange,
          calendars,
        })
      )}
    </Box>
  );
};
