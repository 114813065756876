import {
  useTheme,
  Box,
  Typography,
  Button,
  Link,
  Divider,
} from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import DateTimeLabel from "../../components/label/DateTimeLabel";
import CardGrid from "../../components/cards/layout/CardGrid";
import CardContainer from "../../components/cards/layout/CardContainer";
import useFetch from "../../api/useFetch";
import MoneyLabel from "../../components/label/MoneyLabel";
import { InfoTypes } from "../invoices/invoiceTypes";
import InfoCard from "../../components/cards/InfoCard";
import PreviewPositions from "../../components/preview/PreviewPositions";
import {
  API_GET,
  API_PATCH,
  API_POST,
  DBRequest,
  useConfig,
} from "../../api/api";
import DateLabel from "../../components/label/DateLabel";
import moment from "moment";
import MoneyCard from "../../components/cards/MoneyCard";
import PictureNameCard from "../../components/cards/PictureNameCards/PictureNameCard";
import { useState } from "react";
import ExoDialog from "../../components/exo/ExoDialog";
import ExoForm from "../../components/exo/ExoForm";
import * as Yup from "yup";
import { recipientTypeMap } from "../../components/routing/routingMaps";
import Feedback from "../../components/special/Feedback";

const ExtendPaymentReminder = ({ row, onUpdate, onClickEdit, onDelete }) => {
  const { apiData, setApiData } = useFetch(`dunning-processes/${row.id}`);

  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }

  return (
    <Box className="flex flex-col gap-4">
      {apiData && (
        <>
          <PaymentReminderInfos apiData={apiData.data} />
          <ProcessStatusController
            apiData={apiData.data}
            setApiData={handleUpdate}
          />
          <Divider />
          <InvoiceInfoCards invoice={apiData.data.invoice} />
        </>
      )}
    </Box>
  );
};

const PaymentReminderInfos = ({ apiData }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const daysOver = moment().diff(
    moment(apiData.invoice.paymentDeadline),
    "days"
  );

  const infos = [
    {
      label: t("Invoice overdue"),
      value: `${daysOver} ${t("Days")}`,
    },
    {
      label: t("Process Started"),
      value: <DateLabel date={apiData.processStartedAt} />,
    },
  ];

  const validationSchema = Yup.object().shape({
    delivery: Yup.object().shape({
      deliveryMail: Yup.string()
        .email(t("Invalid email format"))
        .required(t("Delivery mail id is required")),
      deliveryName: Yup.string().required(t("Delivery name id is required")),
    }),
  });

  const [editMail, setEditMail] = useState(false);
  const [editMailIsLoading, setEditMailIsLoading] = useState(false);
  const [mailRecipient, setMailRecipient] = useState(null);
  const [currentMail, setCurrentMail] = useState(apiData.targetMail);
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });

  function handleError(e) {
    console.error(e);
  }

  function handleSubmitError(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  const deliveryFields = {
    type: "delivery",
    key: "mailSelect",
    options: ["recipientMail", "contactMail", "customMail"],
  };

  function abortEditMail() {
    setEditMail(false);
  }

  function handleResponse(data) {
    setMailRecipient(data);
    setEditMailIsLoading(false);
  }

  function handleEditMailClick() {
    setEditMailIsLoading(true);
    setEditMail(true);
    DBRequest({
      config,
      path: `${recipientTypeMap[apiData.invoice.recipientType]}/${
        apiData.invoice.recipient.id
      }`,
      method: API_GET,
      onResponse: handleResponse,
      onError: handleError,
    });
  }

  function handleSubmitResponse(data) {
    setCurrentMail(data.data.targetMail);
    setEditMail(false);
  }

  function handleEditMailSubmit(data) {
    DBRequest({
      config,
      path: `dunning-processes/${apiData.id}`,
      method: API_PATCH({ targetMail: data.delivery.deliveryMail }),
      onResponse: handleSubmitResponse,
      //onError: handleSubmitError,
      onFeedback: handleSubmitError,
    });
  }

  return (
    <CardGrid className="overflow-auto">
      <ExoDialog open={editMail} onClose={abortEditMail}>
        <ExoForm
          fields={[
            {
              key: "delivery",
              type: "delivery",
              label: t("Delivery"),
              options: ["recipientMail", "contactMail", "customMail"],
              allowCC: false,
              allowMailContent: false,
            },
          ]}
          startDataCollection={{ recipient: mailRecipient?.data }}
          onSubmit={handleEditMailSubmit}
          onCancle={abortEditMail}
          validationSchema={validationSchema}
          isLoading={editMailIsLoading}
        />
        <Feedback setState={setAlertState} state={alertState} />
      </ExoDialog>
      <InfoCard infos={infos} />
      {apiData.paymentReminders.map((reminder) => (
        <PaymentReminder reminder={reminder} key={reminder.id} />
      ))}
      <CardContainer className="flex justify-around">
        <Typography>{t("Currently selected Mail")}</Typography>
        <Typography>
          {currentMail ? currentMail : t("No Mail found")}
        </Typography>
        <Button
          variant="contained"
          color="info"
          onClick={() => handleEditMailClick()}
        >
          {t("Change Mail")}
        </Button>
      </CardContainer>
    </CardGrid>
  );
};

const PaymentReminder = ({ reminder }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const infos = [
    {
      label: t("New Deadline"),
      value: <DateLabel date={reminder.newDeadline} />,
    },
    {
      label: t("Fees"),
      value: <MoneyLabel money={reminder.fees} />,
    },
    {
      label: t("File"),
      value: reminder.file ? (
        <Link href={reminder.file.temporaryUrl} target="_blank">
          <Button>{t("Download")}</Button>
        </Link>
      ) : (
        <Button disabled>{t("Download")}</Button>
      ),
    },
    {
      label: t("Sent to"),
      value:
        reminder.mailLogs && reminder.mailLogs.length > 0
          ? reminder.mailLogs[reminder.mailLogs.length - 1].receiver
          : "",
    },
    {
      label: t("Sent"),
      value: reminder.mailSentAt ? (
        <DateTimeLabel dateTime={reminder.mailSentAt} />
      ) : (
        t("Not Sent")
      ),
    },
  ];

  return (
    <InfoCard
      infos={infos}
      sx={{
        border:
          reminder.mailSentAt === null
            ? `2px solid ${colors.redAccent[400]}`
            : `2px solid ${colors.greenAccent[400]}`,
      }}
    />
  );
};

const InvoiceInfoCards = ({ invoice }) => {
  const { t } = useTranslation();
  const infos = InfoTypes(invoice);
  return (
    <CardGrid>
      <InfoCard infos={infos} />
      <MoneyCard
        amount={invoice.amount}
        title={`${t("Price")} ${t("Netto")}`}
      />
      <PictureNameCard
        data={invoice.recipient}
        title={t("Recipient")}
        pictureUrl={
          invoice.recipient.logo && invoice.recipient.logo.thumbnailUrl
        }
        name={
          invoice.recipient.firstName
            ? `${invoice.recipient.firstName} ${invoice.recipient.lastName}`
            : invoice.recipient.name
        }
        type={invoice.recipientType}
      />
      <CardContainer
        span={99}
        sx={{
          display: "block",
          padding: "0px",
        }}
      >
        <PreviewPositions positions={invoice.positions || []} />
      </CardContainer>
    </CardGrid>
  );
};

const ProcessStatusController = ({ apiData, setApiData }) => {
  const { t } = useTranslation();
  const config = useConfig();

  function startDunningProcess() {
    DBRequest({
      config,
      path: `dunning-processes/${apiData.id}/start-process`,
      method: API_POST(),
      onResponse: setApiData,
      onError: handleError,
    });
  }

  function changeDunningProcess(status) {
    DBRequest({
      config,
      path: `dunning-processes/${apiData.id}`,
      method: API_PATCH({ status }),
      onResponse: setApiData,
      onError: handleError,
    });
  }

  function handleError(err) {
    console.log(err);
  }

  const statusActions = {
    0: {
      label: t("Start Dunning Process"),
      onClick: startDunningProcess,
      color: "success",
    },
    1: {
      label: t("Pause Dunning Process"),
      onClick: () => changeDunningProcess(2),
      color: "warning",
    },
    2: {
      label: t("Continue Dunning Process"),
      onClick: () => changeDunningProcess(1),
      color: "success",
    },
    3: {
      label: t("Finished"),
      onClick: () => {},
      color: "info",
    },
    4: {
      label: t("Refusal"),
      onClick: () => {},
      color: "error",
    },
  };

  const controlls = statusActions[apiData.status];

  return (
    <Box className="flex justify-center items-center py-4">
      <Button
        variant="contained"
        color={controlls.color}
        onClick={controlls.onClick}
      >
        {controlls.label}
      </Button>
    </Box>
  );
};

export default ExtendPaymentReminder;
